body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #f3f3e5;*/
}

.container {
	/*min-height: 100vh;*/
  /*background-color: white;*/
}

.no-hover:hover, .no-hover:focus, .no-hover:active {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    outline-style:none !important;
	box-shadow:none !important;
	color:initial !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
    background-color: #222720;
    color: white;
    z-index: 11;
}

.close.rbt-close {

    margin-top: -33px;
    padding-right: 10px;
    padding-left: 10px;
}

.table-striped tbody tr {
  transition: 0.1s
}

.table-striped tbody tr:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,.1);
}
.table-striped tbody tr:hover td {
  border-color: rgba(0,0,0,.1);
}

.table-striped tbody tr.selected {
  background-color: rgba(0,0,0,.15);
}
.table-striped tbody tr.selected td {
  border-color: rgba(0,0,0,.15);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker-popper {
    z-index: 2 !important;
}

.react-datepicker__input-container input.form-control[readonly] {
    background-color: #fff;
}

html .react-datepicker__close-icon::after {
    background-color: transparent;
    line-height: 0.5;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

html .react-datepicker__close-icon {

    margin-right: 5px;
    margin-top: -10px;
}

.form-control.is-valid, .was-validated .form-control.novalidate:valid {
    border: 1px solid #ced4da;
  background-image: none;
}

.form-check-input.novalidate.is-valid~.form-check-label, 
.was-validated .form-check-input.novalidate:valid~.form-check-label {
  color: inherit;
}

.modal-content button {
  z-index: 1;
}

.rbt-input-hint-container input {
    padding-right: 35px;
}

.custom-file-upload {
    /*border: 1px solid #ccc;*/
    /*display: inline-block;*/
    /*padding: 6px 12px;*/
    cursor: pointer;
}

.fixed-right {
  right: 0px;
}

.fixed-left {
  left: 0px;
}

.react-datepicker-popper {
  z-index: 100;
}

.refresh-button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

.modal-header {
    background-color: inherit;
    border-bottom: none;
}

.modal-subheading {
    background-color: #e2e71f;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
}

.modal-subheading h5 {
    margin-bottom: 0px;
    font-weight: bold;
}

.modal-title {
  font-weight: bold;
}

.table-striped tbody tr.success-row {
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.table-striped tbody tr.success-row:hover {
    background-color: #c4daca;
}

canvas.signature-pad {
    border: 1px solid #007bff;
    border-radius: 5px;
}

.was-validated .form-control.novalidate.is-valid,
.was-validated .novalidate.is-valid {
  border-color: #28a745;
  /*padding-right: calc(1.5em + .75rem);*/
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.was-validated .form-control.novalidate.is-invalid,
.was-validated .novalidate.is-invalid {

  border-color: #dc3545;
  /*padding-right: calc(1.5em + .75rem);*/
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);

}

.up-down-button {
  display: inline-block;
  pointer-events: none;
  z-index: 100;
}

.up-down-button > div {
    cursor: pointer;
    display: inline;
    margin-right: 7px;
    border: 1px solid #ced4da;
    padding: 3px 13px;
    border-radius: 6px;
    pointer-events: all;
    transition: background-color 0.2s;
    background-color: white;
}

.up-down-button > div:hover {
  color: black;
  background-color: #ced4da;
}

.room {
  border-top: 5px solid #cacaca;
  padding-top: 20px;
  margin-top: 20px;
  position: relative;
}

.room-0 {
  position: relative;
}

@media only screen and (min-width: 576px) {

  .room, .room-0 {
    padding-top: 50px;
  }

}

.remove-room-button {
  top: 10px;
}

.rotate-button {
    font-size: 35px;
    padding: 7px;
    background-color: #e2e71f;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    padding-right: 7px !important;
    cursor: pointer;
    right: 0px;
    left: auto;
}

.remove-image-button {
    font-size: 35px;
    padding: 7px;
    background-color: #ffc107;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    padding-right: 7px !important;
    cursor: pointer;
    right: 0px;
    left: auto;
}

.rotateAnimation {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


@media only screen and (max-width: 425px) {
  .room-name-field {
      font-size: 35px;
      font-weight: bold;
  }
}

.carried-over-icon {
/*    float: right;
    margin-top: 5px;
    position: absolute;
    right: 15px;*/
    margin-left: 5px;
}

.drag {
  background-color: #17a2b8;
  border-radius: 15px;
  position: relative;
}

.drag * {
  opacity: 0;
  pointer-events: none;
}

/*.drag > td:not(.draggableArea) {
  display: none;
}*/

.roomItemItem {
  position: relative;
}

.draggableArea {
  display: none;
  pointer-events: none;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.draggableArea {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  align-items: center;
  justify-content: center;
}

td.draggableArea {
  text-align: center;
}

.drag .draggableArea {
  display: flex;
  opacity: 1;
}

.drag td.draggableArea {
    display: table-cell;
    padding-top: 50px;
    padding-bottom: 50px;
}

.drag .draggableArea svg, .drag .draggableArea svg path {
  opacity: 1;
}

.input-group-append > .dropdown > button {
  height: 100%;
}

.dropdown-menu {
    z-index: 11 !important;
}

tr.inventory-comments-box {
    border: 3px solid #e2e71f;
}

.borderless {
  border: none !important;
}

.richtext-editor .ButtonGroup__root___3lEAn:first-child>div:first-child>button {
  display: block;
}

.richtext-editor .ButtonGroup__root___3lEAn>div>button, .richtext-editor .Dropdown__root___3ALmx {
  display: none;
}

.table-striped tbody tr.heading-row:hover {
  cursor: auto;
  background-color: rgba(0,0,0,.05);
}

input.room-name-field.form-control {
    font-weight: bold;
}

input.item-name-field.form-control {
    font-weight: bold;
}

.room-item {
  position: relative;
}

.delete-autosave-button {
    float: right;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 40px;
    width: 40px;
    padding: 9px 13px;
    background-color: #dc3545;
    color: white;
    border-radius: 5px;
}

.delete-autosave-button:hover {
  background-color: #c82333;
}

.react-datepicker-popper {
    z-index: 1100 !important;
}

tr[variant="success"] {
    background-color: #c3e6cb !important;
}

tr[variant="success"]:hover {
    background-color: #b1dfbb !important;
}

.downloads-list > a {
    display: block;
    margin-bottom: 25px;
}