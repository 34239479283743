.app {
  min-height: 100vh;
}

.btn-sab {
    background-color: #e2e71f;
    color: #222720;
    font-weight: bold;
}

table .btn-sab {
    padding-top: 2px;
    padding-bottom: 2px;
}

.btn-sab:hover {
	background-color: #d2d917;
}