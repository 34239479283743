.display-xxs {
	display: none;
}

@media only screen and (max-width: 375px) {
	/*th:not(:first-child) {
		display: none;
	}*/
	th {
		font-size: 10px;
	}
	.display-xxs {
		display: block;
	}

	.hidden-xxs {
		display: none;
	}

}